/*
 *  Copyleft 2017 by Philipp Giebel (info@ambnet.biz)
 *  Released under GPLv3: http://www.gnu.org/copyleft/gpl.html
 * 
 */

body {
  background: #eeeeee;
  font-size: 16px;
  padding-top: 50px;
}
.card {
  background: #ffffff;
  border: #dddddd;
  border-radius: 1px;
  box-shadow: 0 0 2px 0 #cccccc;
  margin-top: 30px;
}
.card .row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.card .row > div {
  padding: 10px 15px;
}
.card .row .title,
.card .row .date {
  font-size: 1.2em;
  font-weight: bold;
}
.card.card-finished .row .title,
.card.card-finished .row .date {
  background: #dddddd!important;
  color: #ffffff!important;
}
.card.card-finished .row .content {
  color: #999999!important;
}
.card.card-announcement .row .title,
.card.card-announcement .row .date {
  background: #6699dd;
  color: #ffffff;
}
.card.card-alert .row .title,
.card.card-alert .row .date {
  background: #dd8888;
  color: #ffffff;
}
.card.card-noproblems .row .title,
.card.card-noproblems .row .date {
  background: #66aa66!important;
  color: #ffffff!important;
}
.card .row .date {
  text-align: right;
}
.update {
  background: #eeeeee;
}
.update .date {
  font-weight: bold;
}
.update .glyphicon {
  font-size: 1.5em;
}

.addnews #title {
}

.addnews #date {
  text-align: right;
}
.addnews textarea {
  clear: both;
}
.addnews {
  margin: 20px 0;
}
.addnews .row {
  padding: 10px 0;
}
.addnews .row.light,
.addnews .row.update.light {
  background: #f6f6f6;
  border-radius: 2px;
}
.addnews .radios .btn.focus,
.addnews .radios .btn.active.focus,
.addnews .radios .btn.active {
  color: #ffffff;
  border-color: #aa0000;
  background: #dd0000;
}
.addnews .checkboxes .btn.active {
  color: #ffffff;
  border-color: #006600;
  background: #008800;
}
.addnews .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 64px;
}
.addnews .bootstrap-select .btn-default {
  background: transparent;
  border: none;
}
.bootstrap-select .dropdown-toggle:focus,
.focus,
.active.focus {
  outline: 0!important;
}
.addnews .btn-group.open .dropdown-toggle {
  box-shadow: none;
}
.addnews .open>.dropdown-toggle.btn-default:hover {
  background: transparent;
  box-shadow: none;
}
.addnews .head button[type="submit"],
.addnews .head a.reset {
  border-radius: 50%;
  height: 55px;
  width: 55px;
  line-height: 2;
}
.addnews h3 {
  margin: 0;
  padding: 5px 15px;
  background: #eeeeee;
}
.addnews .row.update {
  background: #eeeeee;
}
.alignright {
  text-align: right;
}
.btn-delupdate {
  margin-top: 10px;
}
.btn-disabled {
  color: #999999!important;
  background: #ffffff!important;
  border-color: #cccccc!important;
}
.btn-disabled:active,
.btn-disabled:focus,
.btn-disabled:hover {
  color: #999999!important;
  background: #ffffff!important;
  cursor: default;
  border-color: #cccccc!important;
  box-shadow: none;
}

/****** LOGIN MODAL ******/
.loginmodal-container {
  padding: 30px;
  max-width: 350px;
  width: 100% !important;
  background-color: #F7F7F7;
  margin: 50px auto; 
  border-radius: 2px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  font-family: roboto;
}

.loginmodal-container h1 {
  text-align: center;
  font-size: 1.8em;
  font-family: roboto;
}

.loginmodal-container input[type=submit] {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  position: relative;
}

.loginmodal-container input[type=text], input[type=password] {
  height: 44px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0c0;
  /* border-radius: 2px; */
  padding: 0 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  transition: border .2s ease-in-out;
}

.loginmodal-container input[type=text]:hover, input[type=password]:hover {
  border: 1px solid #b9b9b9;
  border-top: 1px solid #a0a0a0;
  -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}

.loginmodal {
  text-align: center;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  height: 36px;
  padding: 0 8px;
/* border-radius: 3px; */
/* -webkit-user-select: none;
  user-select: none; */
}

.loginmodal-submit {
  /* border: 1px solid #3079ed; */
  border: 0px;
  color: #fff;
  text-shadow: 0 1px rgba(0,0,0,0.1); 
  background-color: #4d90fe;
  padding: 17px 0px;
  font-family: roboto;
  font-size: 14px;
  /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#4787ed)); */
}

.loginmodal-submit:hover {
  /* border: 1px solid #2f5bb7; */
  border: 0px;
  text-shadow: 0 1px rgba(0,0,0,0.3);
  background-color: #357ae8;
  /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#357ae8)); */
}

.loginmodal-container a {
  text-decoration: none;
  color: #666;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  opacity: 0.6;
  transition: opacity ease 0.5s;
} 

.login-help{
  font-size: 12px;
}
.navbar-brand img {
  height: 100%;
}
.navbar .btn {
  margin: 8px 0;
  height: 34px;
}
.footer {
  margin: 25px 0;
  
}
.footer div {
  background: #ffffff;
  padding: 5px 15px;
  box-shadow: 0 0 2px 0 #cccccc;
  text-align: center;
}
.btn {
  border-radius: 1px;
}
.btn-default {
  border-color: #dddddd;
}
.mobilebreak {
  display: none;
  margin: 0;
  padding: 0;
  height: 10px;
}

.tab-content nav {
  margin-top: -60px;
  height: 0;
}
.admin .panel-group {
  margin-top: 15px;
}
.admin .panel-body {
  background: #f6f6f6;
}
button,
input[type="submit"],
a {
  transition: all .2s ease-in-out;
}
@media (max-width: 767px) {
  .head > div {
    margin-bottom: 15px;
  }
  .btn-lg {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
  }
  .toggle-handle.btn-lg {
    width: 0;
  }
  .toggle-on.btn-lg,
  .toggle-off.btn-lg {
    padding-left: 24px;
  }
  .toggle.btn-lg {
    min-width: 59px;
    min-height: 34px;
  }
  .mobilebreak {
    display: block;
  }
  table .title {
    width: 40%;
  }
  table .date {
    width: 20%;
  }
  table .actions {
    width: 40%;
  }
}